import styled from "styled-components";

import { ButtonPrimary } from "../buttons/ButtonPrimary";
import download_graphic from "../../../../assets/images/download_graphic.svg";

export const ModalGetModel = (props) => {
  return (
    <Container>
      <Content>
        <Image src={download_graphic} />
        <Text>Download or reqest a 3D model</Text>
        <ButtonsWrapper>
          <ButtonPrimary
            text="Download GLTF"
            theme={"blue"}
            style={{ width: "100%" }}
            onClick={props.onModelDownload}
          />
          <ButtonPrimary
            text="Reqest other 3D formats"
            theme={"blue"}
            style={{ width: "100%" }}
            onClick={props.onModelReqest}
          />
          <ButtonPrimary
            text="Cancel"
            style={{ width: "100%" }}
            onClick={props.onCancel}
          />
        </ButtonsWrapper>
      </Content>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 0px;
  position: absolute;
  top: 22.5%;
  background: #fcfcfc;
  filter: drop-shadow(0px 0px 20px rgba(51, 51, 51, 0.05));
  border-radius: 6px;
  overflow: hidden;
`;
const Content = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px 0px 0px;
  background: #fcfcfc;
  border-top: 14px solid #0072c9;
  width: 445px;
`;
const Image = styled.img`
  width: 48px;
  height: 48px;
`;
const Text = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  gap: 10px;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding: 12px 36px 32px;
  gap: 24px;
`;
