import { useEffect, useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";

import { ModalGetModel } from "../molecules/modals/ModalGetModel";
import { selectionStore } from "../../../lib/states/store";
import useKeyPress from "../../../lib/hooks/useKeyPress";
import { onDownloadModel } from "../../../lib/utils/download";

export const ModalWindow = (props) => {
  const isModalOpen = selectionStore((state) => state.openModal);
  const modalRef = useRef();
  const modalBGRef = useRef();

  useKeyPress("Escape", () => {
    selectionStore.setState({ openModal: false });
  });
  useEffect(() => {
    isModalOpen
      ? gsap.to([modalBGRef.current, modalRef.current], {
          opacity: 1,
          display: "flex",
          duration: 0.25,
        })
      : gsap.to([modalBGRef.current, modalRef.current], {
          opacity: 0,
          display: "none",
          duration: 0.25,
        });

    return () => {};
  }, [isModalOpen]);
  const handleCancel = () => {
    selectionStore.setState({ openModal: isModalOpen ? false : true });
  };
  return (
    <>
      <Content ref={modalRef}>
        <ModalGetModel
          onCancel={() => handleCancel()}
          onModelDownload={() => {
            onDownloadModel(props.modelUrl);
            handleCancel();
          }}
          onModelReqest={() => {
            window.location = `mailto:fraser.reid@sky.uk?subject=3D Model Reqest`;
            handleCancel();
          }}
        />
      </Content>
      <Container
        ref={modalBGRef}
        onClick={() => {
          handleCancel();
        }}
      ></Container>
    </>
  );
};
const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: none;
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 998;
  background: rgba(0, 0, 0, 0.8);
`;
const Content = styled.div`
  width: auto;
  height: auto;
  display: none;
  left: 50%;
  top: 25%;
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  position: absolute;
  z-index: 999;
`;
