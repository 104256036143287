import { useState, useEffect } from "react";

export const useFetch = (url, options) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [serverError, setServerError] = useState(null);
  useEffect(() => {
    setLoading(true);
    console.log("Loading...");
    const fetchData = async () => {
      try {
        const response = await fetch(url, options);
        const json = await response.json();
        setData(json);
        setLoading(false);
        console.log("Done Loading");
      } catch (error) {
        setServerError(error);
        setLoading(false);
        console.log(serverError);
      }
    };

    fetchData();
  }, []);
  return [data, loading];
};
