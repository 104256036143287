import styled from "styled-components";
const PageWrapper = ({ children }) => {
  return <Container>{children}</Container>;
};

const Container = styled.div`
  display: flex;
  z-index: -1;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  padding-bottom: 48px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #ebebeb 0%,
    rgba(222, 222, 222, 0) 100%
  );
`;
export default PageWrapper;
