import styled from "styled-components";
import ico_download from "../../../../assets/images/ico_download.svg";
import { MaterialPreview } from "../../../geometry/MaterialPreview";
import { ButtonPrimary } from "../buttons/ButtonPrimary";

export const CardMaterial = (props) => {
  return (
    <Container isSelected={props.isSelected}>
      <MaterialContainer>
        <DetailsContainer onClick={props.onSelectMaterialClick}>
          <ImageContainer>
            <MaterialPreview
              textures={props.textures.materials[props.meshName]}
              width={60}
              height={60}
            />
          </ImageContainer>
          <LabelsContainer>
            <p>{props.meshName}</p>
            <p>Material</p>
            <p>{props.materialName}</p>
          </LabelsContainer>
        </DetailsContainer>
        <ButtonContainer onClick={props.onDownloadMaterialClick}>
          <Icon src={ico_download} />
        </ButtonContainer>
      </MaterialContainer>
      {props.materialOptions.length > 1 && (
        <AllMaterialsContainer
          onMaterialOptionClick={props.onMaterialOptionClick}
          onDownloadOptionsClick={props.onDownloadOptionsClick}
          isOpen={props.isSelected}
          options={props.materialOptions}
          meshName={props.meshName}
        />
      )}
    </Container>
  );
};

const AllMaterialsContainer = (props) => {
  const maxRailLength = 6;
  const optionCount = props.options.length;
  const railCount = Math.ceil(optionCount / maxRailLength);

  return (
    <OptionsContainer isOpen={props.isOpen} rails={railCount}>
      <ThumbnailsContainer>
        <p>Material Options</p>
        <Rail>
          {Object.entries(props.options).map((item, index) => (
            <ImageContainer
              key={index}
              onClick={() => props.onMaterialOptionClick(props.meshName, index)}
            >
              <MaterialPreview textures={item[1]} width={36} height={36} />
            </ImageContainer>
          ))}
        </Rail>
      </ThumbnailsContainer>
      <ButtonPrimary
        onClick={props.onDownloadOptionsClick}
        text="Download all options"
        style={{
          width: "100%",
          height: "26px",
          fontSize: "14px",
          padding: "10px 8px",
        }}
        iconDivider={true}
        icon={ico_download}
      />
    </OptionsContainer>
  );
};

const Image = styled.div`
  background-image: url(${(props) => props.url});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  aspect-ratio: 1;
  border-radius: 4px;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const Rail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 9px;
  width: 100%;
  flex: none;
  order: 1;
  flex-grow: 0;
  flex-wrap: wrap;
  ${Image} {
    height: 36px;
  }
  ${ImageContainer}:hover {
    cursor: pointer;
    outline: 1px solid #0072c9;
    border-radius: 4px;
    opacity: 75%;
  }
`;

const ThumbnailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 0px 8px;
  gap: 4px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  > p {
    font-family: "Sky Text";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #333333;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
`;

const OptionsContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;

  max-height: ${(props) =>
    props.isOpen ? (91 + props.rails * 42).toString() + "px" : "0px"};
  padding: ${(props) => (props.isOpen ? "0px 12px 18px" : "0px 12px")};
  border-top: ${(props) => (props.isOpen ? "1px solid #F5F5F5" : "0px")};
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  overflow: hidden;
  transition: max-height 0.5s, padding 0.5s, opacity 0.5s;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  border-left: 1px solid #f5f5f5;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  :hover {
    background: #f2f2f2;
  }
  :hover ${Icon} {
    filter: invert(21%) sepia(77%) saturate(3506%) hue-rotate(193deg)
      brightness(98%) contrast(99%);
  }

  :active {
    border-left: 1px solid #0072c9;
    opacity: 25%;
  }
`;

const LabelsContainer = styled.div`
  padding: 0px 0px 0px 12px;
  text-align: left;
  color: #333333;

  > p:nth-child(1) {
    font-family: SkyTextMedium;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.01em;
    padding-bottom: 4px;
  }

  > p:nth-child(2) {
    font-size: 12px;
    line-height: 14px;
  }

  > p:nth-child(3) {
    font-family: SkyTextMedium;
    font-size: 14px;
    line-height: 14px;
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;

  flex: none;
  order: 0;
  flex-grow: 1;

  ${Image} {
    height: 60px;
  }
`;

const MaterialContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  border-width: 1px 0px;
  border-style: solid;
  border-color: #f5f5f5;
  background: ${(props) => (props.isSelected ? "#FCFCFC" : "#FFFFFF")};
  box-shadow: ${(props) =>
    props.isSelected &&
    "inset 0px -2px 6px rgba(0, 0, 0, 0.05), inset -2px 2px 4px rgba(0, 0, 0, 0.05)"};
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  :hover {
    box-shadow: inset 0px -2px 6px rgba(0, 0, 0, 0.05),
      inset -2px 2px 4px rgba(0, 0, 0, 0.05);
  }

  :hover ${LabelsContainer} > p:nth-child(1),
  :hover ${LabelsContainer} > p:nth-child(3) {
    color: #0072c9;
  }

  ${LabelsContainer} > p:nth-child(1),
${LabelsContainer} > p:nth-child(3) {
    color: ${(props) => props.isSelected && "#0072C9"};
  }

  ${ButtonContainer}:hover {
    border-left: ${(props) => (props.isSelected ? "1px solid #0072C9" : "")};
  }
`;
