import { useEffect, useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";

export const ButtonTheme = (props) => {
  const buttonRef = useRef();
  const onEnter = () => {
    gsap.to(buttonRef.current, {
      borderBottom: "2px solid #3F94D6",
      color: "#3F94D6",
      duration: 0.125,
    });
  };
  const onLeave = () => {
    if (!props.isSelected) {
      gsap.to(buttonRef.current, {
        borderBottom: "2px solid #ffffff",
        color: "#333333",
        duration: 0.125,
      });
    }
  };
  useEffect(() => {
    if (props.isSelected) {
      gsap.to(buttonRef.current, {
        borderBottom: "2px solid #3F94D6",
        color: "#333333",
        duration: 0.125,
      });
    } else {
      gsap.to(buttonRef.current, {
        borderBottom: "2px solid #ffffff",
        color: "#333333",
        duration: 0.125,
      });
    }
    return () => {};
  }, [props.isSelected]);

  return (
    <Container
      ref={buttonRef}
      onClick={props.onClick}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
    >
      <IconTheme style={{ background: props.color }} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  height:54px;
  border-bottom: 2px solid #ffffff};
`;
const IconTheme = styled.div`
  display: block;
  margin-top: 2px;
  width: 24px;
  height: 24px;
  border-radius: 36px;
  cursor: pointer;
  border: 1px solid #cccccc;
`;
