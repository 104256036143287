import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFetch } from "../lib/hooks/useFetch";
import PageWrapper from "../components/ui/atoms/PageWrapper";
import { CardThumb } from "../components/ui/molecules/cards/CardThumb";

export const ProductsPage = () => {
  const [data, loading] = useFetch("assets/products/products.json");
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const onCardClick = (item) => {
    item.modelUrl && item.prmaterials
      ? navigate(`/${item.name}`, { state: { item } })
      : null;
  };
  useEffect(() => {
    if (!loading) {
      setProducts(data.products);
    }
  }, [loading]);
  return (
    <PageWrapper>
      {loading ? (
        <div>Loading</div>
      ) : (
        <Container>
          <Header>
            <h2>Select product</h2>
          </Header>
          <Content>
            {products.map((item, index) => {
              return (
                <CardThumb
                  key={index}
                  text={item.name}
                  image={item.image}
                  onClick={() => {
                    onCardClick(item);
                  }}
                />
              );
            })}
          </Content>
        </Container>
      )}
    </PageWrapper>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px 18px 16px;
  margin-bottom: 25%;
  position: absolute;
  width: 70%;
  top: 96px;
  background: #fcfcfc;
  box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);
  border-radius: 12px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0px 24px 4px 24px;
  position: static;
  width: 75%;
  height: 52px;
  left: 0px;
  top: 0px;
  background: #f9f9f9;
  border-radius: 8px 8px 0px 0px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 24px 32px;
  position: static;
  background: #f9f9f9;
  border-radius: 0px 8px 8px 8px;
  flex: none;
  align-self: stretch;
`;
