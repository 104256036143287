import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";

import { useGetTexture } from "../../lib/hooks/useGetTexture";
// import { LoaderPreview } from "../ui/atoms/LoaderPreview";
// import { Loader } from "@react-three/drei";

const Sphere = (props) => {
  const texturesObj = useGetTexture(props.textures);
  // console.log(texturesObj);

  return (
    <mesh>
      <sphereGeometry args={[2.5, 60, 60]} />
      <meshPhysicalMaterial
        map={texturesObj.map}
        metalnessMap={texturesObj.metalnessMap}
        normalMap={texturesObj.normalMap}
        roughnessMap={texturesObj.roughnessMap}
      />
    </mesh>
  );
};

export const MaterialPreview = (props) => {
  return (
    <div style={{ aspectRatio: 1, overflow: "hidden", borderRadius: 4 }}>
      <Canvas
        style={{
          width: props.width,
          height: props.height,
          backgroundColor: "#E0E0E0",
        }}
      >
        <pointLight position={[5, 10, -10]} intensity={1} />
        <pointLight position={[0, 0, 10]} intensity={1} />
        <Suspense fallback={null}>
          <Sphere textures={props.textures} />
        </Suspense>
      </Canvas>
    </div>
  );
};
