import { useEffect, useState } from "react";
import styled from "styled-components";
import { ButtonPrimary } from "../molecules/buttons/ButtonPrimary";
import { CardMaterial } from "../molecules/cards/CardMaterial";
import { selectionStore, materialStore } from "../../../lib/states/store";
import {
  onDownloadMaterial,
  onDownloadAllMaterials,
  onDownloadMaterialOptions,
} from "../../../lib/utils/download";

export const Drawer = () => {
  const prMaterials = materialStore((state) => state.prMaterials);
  const prMaterialOptions = materialStore((state) => state.prMaterialOptions);
  const selectedMaterials = selectionStore((state) => state.selectedMaterials);
  const selectedMeshName = selectionStore((state) => state.selectedMeshName);

  const [allDrawerMaterials, setAllDrawerMaterials] = useState();

  useEffect(() => {
    if (prMaterials && prMaterialOptions) {
      let drawerMaterials = {};
      Object.keys(prMaterials.materials).forEach((meshName) => {
        drawerMaterials[meshName] = prMaterialOptions[meshName]
          ? [prMaterials.materials[meshName]].concat(
              prMaterialOptions[meshName]
            )
          : [prMaterials.materials[meshName]];
      });
      setAllDrawerMaterials(drawerMaterials);
    }
    return () => {};
  }, [prMaterials, prMaterialOptions]);

  const UpdateSelection = (meshName) => {
    selectionStore.setState({
      selectedMeshName: meshName == selectedMeshName ? "" : meshName,
    });
  };

  const UpdateMaterialOption = (meshName, materialIndex) => {
    const selectedMaterialsClone = structuredClone(selectedMaterials);
    selectedMaterialsClone.materials[meshName] =
      allDrawerMaterials[meshName][materialIndex];
    selectionStore.setState({
      selectedMaterials: selectedMaterialsClone,
    });
  };

  return (
    <Container>
      <Head>
        <Title>Materials</Title>
        <ButtonContainer>
          <ButtonPrimary
            text="Download all materials"
            style={{ width: "100%", height: "34px" }}
            onClick={() => {
              onDownloadAllMaterials(selectedMaterials.materials);
            }}
          />
        </ButtonContainer>
      </Head>
      <Content>
        {!allDrawerMaterials ? (
          <div>Loading</div>
        ) : (
          <>
            {Object.entries(allDrawerMaterials).map((item, index) => {
              const meshName = item[0];
              const materialOptions = item[1];
              let selectedMaterialOption = materialOptions[0];
              for (let i = 0; i < materialOptions.length; i++) {
                if (
                  materialOptions[i].name ==
                  selectedMaterials.materials[meshName].name
                ) {
                  selectedMaterialOption = materialOptions[i];
                  break;
                }
              }

              return (
                <CardMaterial
                  key={index}
                  meshName={meshName}
                  materialName={selectedMaterialOption.name}
                  textures={selectedMaterials}
                  image={selectedMaterialOption.image}
                  isSelected={selectedMeshName == meshName}
                  materialOptions={materialOptions}
                  onMaterialOptionClick={UpdateMaterialOption}
                  onSelectMaterialClick={() => {
                    UpdateSelection(meshName);
                  }}
                  onDownloadMaterialClick={() => {
                    onDownloadMaterial(selectedMaterialOption.textures);
                  }}
                  onDownloadOptionsClick={() => {
                    onDownloadMaterialOptions(materialOptions);
                  }}
                />
              );
            })}
          </>
        )}
      </Content>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 1px;

  position: absolute;
  width: 320px;
  height: calc(100vh - 56px);
  right: 0px;
  top: 56px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  flex-shrink: 1;
  overflow-y: auto;
  align-self: stretch;
`;

const Head = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 12px 0px;
  background: #fcfcfc;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  flex: none;
  order: 0;
  align-self: stretch;
  flex-shrink: 1;
`;

const Title = styled.h2`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 12px 12px;
  border-bottom: 1px solid #f5f5f5;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 10px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  > * {
    margin: 0 auto;
    padding: 10px 18px 10px 18px;
    border: 1px solid rgba(0, 114, 201, 0.75);
  }
`;
