import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LoginPage } from "./pages/LoginPage";
import { ProductsPage } from "./pages/ProductsPage";
import { ScenesPage } from "./pages/ScenesPage";
import "./constants/fonts.css";
import "./App.css";

const App = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* <AnimatePresence exitBeforeEnter initial={false}> */}
          <Route exact path="/" element={<LoginPage />} />
          <Route exact path="/products" element={<ProductsPage />} />
          <Route exact path="/*" element={<ScenesPage />} />
        </Routes>
        {/* </AnimatePresence> */}
      </Router>
    </div>
  );
};
export default App;
