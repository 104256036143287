import styled from "styled-components";

export const ButtonPrimary = (props) => {
  return (
    <Button onClick={props.onClick} style={props.style} theme={props.theme}>
      <div style={{ marginLeft: props.icon ? "auto" : "unset" }}>
        {props.text}
      </div>

      {props.iconDivider && <IconDivider />}
      {props.icon && (
        <Icon
          src={props.icon}
          divider={props.iconDivider}
          style={props.iconStyle}
          theme={props.theme}
        />
      )}
    </Button>
  );
};

const IconDivider = styled.div`
  border-left: 1px solid #f5f5f5;
  height: 20px;
  margin-left: auto;
`;

const Icon = styled.img`
  height: 14px;
  flex: none;
  order: 2;
  flex-grow: 0;
  padding-left: 8px;
  transition: all 0.125s;

  filter: ${(props) =>
    props.theme == "blue"
      ? "invert(100%) sepia(0%) saturate(7500%) hue-rotate(290deg) brightness(106%) contrast(104%)"
      : "unset"};
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0072c9;
  border-radius: 3px;
  width: max-content;
  height: 36px;
  padding: 5px 8px;
  transition: all 0.125s;
  font-size: 16px;

  background: ${(props) => (props.theme == "blue" ? "#0072C9" : "#ffffff")};
  color: ${(props) => (props.theme == "blue" ? "#ffffff" : "#333333")};

  :hover {
    border-color: #3f94d6;
    color: ${(props) => props.theme != "blue" && "#3F94D6"};
    background: ${(props) => props.theme == "blue" && "#1484d0"};
  }

  :hover ${Icon} {
    filter: ${(props) =>
      props.theme != "blue" &&
      "invert(48%) sepia(96%) saturate(340%) hue-rotate(163deg) brightness(92%) contrast(93%)"};
  }
`;
