import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ButtonPrimary } from "../components/ui/molecules/buttons/ButtonPrimary";
import PageWrapper from "../components/ui/atoms/PageWrapper";

export const LoginPage = () => {
  const navigate = useNavigate();
  const onLoginClick = () => {
    navigate("/products");
  };
  document.addEventListener("DOMContentLoaded", function () {
    const sso = document.getElementById("sso");

    sso.addEventListener("loggedIn", (event) => {
      console.log("Logged in with user:", event.detail.email);
      navigate("/products");
    });
  });
  const buttonPrimary = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #0072c9",
    borderRadius: 3,
    width: "max-content",
    height: 36,
    padding: "5px 8px",
    transition: "all 0.125",
    fontSize: 16,
    fontWeight: "normal",
    background: "#0072C9",
    color: "#ffffff",
  };
  const buttonSecondary = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #0072c9",
    borderRadius: 3,
    width: "max-content",
    height: 36,
    padding: "5px 8px",
    transition: "all 0.125",
    fontSize: 16,
    fontWeight: "normal",
    background: "#ffffff",
    color: "#333333",
  };
  return (
    <PageWrapper>
      <Container>
        <Content>
          <Header>
            <h1>Welcome to Chameleon</h1>
          </Header>
          <h4>A tool for sourcing Sky product materials</h4>

          <ixd-sso
            id="sso"
            user-pool-id="eu-west-2_MRVGqthhb"
            user-pool-web-client-id="1bl666po7n7rvmunkpcivef4a0"
            oauth-domain="chameleon.auth.eu-west-2.amazoncognito.com"
          >
            <div slot="logged-in">
              <p>You are logged in.</p>
              <Row>
                <button style={buttonSecondary} data-ixd-sso="logout">
                  Log out
                </button>
                <ButtonPrimary
                  theme="blue"
                  text="View products"
                  onClick={onLoginClick}
                />
              </Row>
            </div>
            <div slot="logged-out">
              {/* Logged out content here! */}

              <p>
                Log in with your Sky credentials or provided user name and
                password
              </p>
              <Row>
                <button style={buttonPrimary} data-ixd-sso="login">
                  Login to Chameleon
                </button>
                {/* <ButtonPrimary data-ixd-sso="login" theme="blue" text="Login" /> */}
              </Row>
            </div>
            <div slot="pending-auth">Loading...</div>
            <div slot="unauthorized">
              Message here when a login is unsuccessful due to not being
              authorized
            </div>
          </ixd-sso>
          {/* <ButtonPrimary text={"I'm a Sky guest"} onClick={onLoginClick} /> */}
        </Content>
      </Container>
    </PageWrapper>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 18px 18px 16px;
  position: absolute;
  width: 50%;
  top: 96px;
  background: #fcfcfc;
  box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);
  border-radius: 12px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 24px 32px;
  position: static;
  background: #f9f9f9;
  border-radius: 0px 8px 8px 8px;
  flex: none;
  align-self: stretch;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 4px 0px 4px 24px;
  position: static;
  height: 52px;
  left: 0px;
  top: 0px;
  background: #f9f9f9;
  border-radius: 8px 8px 0px 0px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  gap: 12px;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 24px 32px;
  position: static;
  background: #f9f9f9;
  border-radius: 0px 8px 8px 8px;
  flex: none;
  align-self: stretch;
`;
