import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { selectionStore } from "../../../lib/states/store";
import { ButtonTheme } from "../molecules/buttons/ButtonTheme";
import ico_close from "../../../assets/images/ico_close.svg";
import ico_upload from "../../../assets/images/ico_upload.svg";
import { ButtonPrimary } from "../molecules/buttons/ButtonPrimary";
const NavTop = (props) => {
  const navigate = useNavigate();
  const [themes, setThemes] = useState();
  const [loading, setLoading] = useState(true);

  const selectedTheme = selectionStore((state) => state.selectedTheme);
  const isModalOpen = selectionStore((state) => state.openModal);

  useEffect(() => {
    if (props.materials) {
      setThemes(props.materials);
      setLoading(false);
    }
    return () => {};
  }, [props.materials]);
  const handleThemeClick = (index) => {
    selectionStore.setState({ selectedTheme: index });
  };
  const handleGetModelClick = () => {
    selectionStore.setState({ openModal: isModalOpen ? false : true });
  };
  return (
    <Container>
      <Logo url="assets/images/sky_logo.png"></Logo>
      <Content>
        <ProductTitle>
          <Label>Product Name</Label>
          <Title>{props.productName}</Title>
        </ProductTitle>
        {!loading && (
          <Themes>
            <ThemeTitle>Themes</ThemeTitle>

            {themes.map((item, index) => {
              return (
                <ButtonTheme
                  key={index}
                  color={item.color}
                  isSelected={selectedTheme == index ? true : false}
                  onClick={() => {
                    handleThemeClick(index);
                  }}
                />
              );
            })}
          </Themes>
        )}
        <Buttons>
          <ButtonPrimary
            text={"Get 3D model"}
            style={{ width: "162px", height: "40px" }}
            icon={ico_upload}
            iconStyle={{ height: "20px", marginRight: "auto" }}
            theme={"blue"}
            onClick={() => handleGetModelClick()}
          />
        </Buttons>
      </Content>
      <ButtonClose
        onClick={() => {
          navigate(-1);
        }}
      >
        <Icon src={ico_close} />
      </ButtonClose>
    </Container>
  );
};
export default NavTop;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 56px;
  background: #ffffff;
  z-index: 2;
  box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.05);
  min-width: 750px;
  position: absolute;
`;
const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;
  background: #ffffff;
  text-align: left;
  z-index: 2;
`;
const Logo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  padding: 0px 5px;
  border-right: 1px solid #f5f5f5;
  background: url(${(props) => props.url});
  background-position: 50% 55%;
  background-repeat: no-repeat;
  background-size: 43.75px;
`;
const ProductTitle = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  width: -webkit-fill-available;
`;
const Label = styled.div`
  font-size: 14px;
`;
const Title = styled.h3`
  font-family: "SkyTextMedium";
  font-size: 18px;
`;
const ThemeTitle = styled.div`
  font-family: "SkyTextMedium";
  font-size: 12px;
  border-right: 1px solid #cccccc;
  padding-right: 12px;
`;
const Themes = styled.div`
  display: flex;
  padding: 0px 24px;
  height: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  border-left: 1px solid #f5f5f5;
`;
const Buttons = styled.div`
  display: flex;
  padding: 0px 24px;
  width: auto;
  height: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  border-left: 1px solid #f5f5f5;
`;
const Icon = styled.img`
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
`;
const ButtonClose = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 56px;
  cursor: pointer;
  border-left: 1px solid #f5f5f5;

  :hover ${Icon} {
    filter: invert(27%) sepia(89%) saturate(1730%) hue-rotate(188deg)
      brightness(95%) contrast(101%);
  }
`;
