import { RepeatWrapping } from "three";
import { useTexture } from "@react-three/drei";

const wrapTexture = (textureMap, repeatX, repeatY, anisotropy, flipY) => {
  const x = repeatX ? repeatX : 1;
  const y = repeatY ? repeatY : 1;
  const a = anisotropy ? anisotropy : 16;
  let textureMapWrapped = [];
  let textureMapObj;

  textureMap.map((item) => {
    item[1].wrapS = item[1].wrapT = RepeatWrapping;
    item[1].repeat.set(x, y);
    item[1].anisotropy = a;
    item[1].flipY = flipY;
    textureMapWrapped.push(item);
    textureMapObj = Object.fromEntries(textureMapWrapped);
  });
  return textureMapObj;
};
export const getTexture = (textureObj, repeatX, repeatY, anisotropy, flipY) => {
  const textureMap = useTexture(textureObj);
  const textArr = Object.entries(textureMap);
  const wrappedTexture = wrapTexture(
    textArr,
    repeatX,
    repeatY,
    anisotropy,
    flipY
  );
  return wrappedTexture;
};
