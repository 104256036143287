import { getTexture } from "../../lib/helpers/wrapTexture";

export const useGetTexture = (obj) => {
  // console.log(obj);
  let materialsArr;
  let texturesObj;
  // let texturesArr = [];
  // let materialsObj = {};

  if (obj) {
    materialsArr = Object.entries(obj);
    materialsArr.map((item) => {
      let textureMaps = Object.entries(item[1]).filter((key) => {
        if (key.indexOf("map") === 0 || key[0].endsWith("Map")) {
          return key;
        }
      });
      const entries = new Map(textureMaps);
      const obj = Object.fromEntries(entries);
      texturesObj = getTexture(obj);
      // console.log(textures);
      // return textures;
      // let materialName = item[0];
      // let materialParams = item[1].params;
      // console.log(materialParams);
      // texturesObj = {
      //   [materialName]: { textures: textures, params: materialParams },
      // };
      // texturesArr.push(texturesObj);
      // for (const value of Object.values(texturesArr)) {
      //   Object.assign(materialsObj, value);
      // }
    });
  }
  return texturesObj;
};
