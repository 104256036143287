import styled from "styled-components";
import { Image } from "../../atoms/ThumbImage";
export const CardThumb = (props) => {
  return (
    <Container onClick={props.onClick}>
      <Image url={props.image}></Image>
      <Text>
        <h4>{props.text}</h4>
      </Text>
    </Container>
  );
};
const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 0px solid #000;
  border-radius: 4px;
  background: #ffffff;
  cursor: pointer;
  align-self: strech;
  flex: auto;
  flex-grow: 1;
  max-width: 32%;
  padding-bottom: 24px;
  box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.05);
  overflow: hidden;
`;
const Text = styled.div`
  padding: 12px 12px;
  text-align: left;
  width: fit-content;
  background: #fff;
  position: absolute;
  bottom: 0;
  border-radius: 0 4px 0 0;
`;
