import { Suspense, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import {
  Environment,
  OrbitControls,
  Loader,
  PerspectiveCamera,
} from "@react-three/drei";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { useFetch } from "../lib/hooks/useFetch";
import NavTop from "../components/ui/organisms/NavTop";
import { ProductScene } from "../components/scenes/ProductScene";
import { materialStore, selectionStore } from "../lib/states/store";
import { Drawer } from "../components/ui/organisms/Drawer";
import { ModalWindow } from "../components/ui/organisms/ModalWindow";
// import { LoaderModel } from "../components/ui/atoms/LoaderModel";

export const ScenesPage = () => {
  const location = useLocation();
  const [prMaterialsData, loadingPrMaterialsData] = useFetch(
    location.state.item.prmaterials
  );

  // Zustand state management -> see ../lib/states/store
  const prMaterials = materialStore((state) => state.prMaterials);
  const selectedMaterials = selectionStore((state) => state.selectedMaterials);
  const prMaterialOptions = materialStore((state) => state.prMaterialOptions);
  const selectedTheme = selectionStore((state) => state.selectedTheme);
  const selectedMeshName = selectionStore((state) => state.selectedMeshName);

  // Once prMaterials are loaded, or the theme changes, update the material store
  useEffect(() => {
    if (
      !loadingPrMaterialsData &&
      prMaterialsData.themes &&
      prMaterialsData.materialOptions
    ) {
      materialStore.setState({
        prMaterials: prMaterialsData.themes[selectedTheme],
        prMaterialOptions: prMaterialsData.materialOptions,
      });

      selectionStore.setState({
        selectedMaterials: prMaterialsData.themes[selectedTheme],
      });
    }
  }, [loadingPrMaterialsData, selectedTheme]);

  // When model mesh clicked -> Update selection store
  const onPrMeshClick = (e) => {
    e.stopPropagation();
    selectionStore.setState({
      selectedMeshName: e.object.name == selectedMeshName ? "" : e.object.name,
    });
  };

  // When mesh missed -> de-select mesh
  const onPointerMissed = (e) => {
    e.stopPropagation();
    selectionStore.setState({
      selectedMeshName: "",
    });
  };

  const onPointerEnter = (e) => {
    e.stopPropagation();
    document.body.style.cursor = "pointer";
  };

  const onPointerLeave = (e) => {
    e.stopPropagation();
    document.body.style.cursor = "default";
  };

  return (
    <Container>
      <ModalWindow modelUrl={location.state.item.modelUrl} />
      <NavTop
        productName={location.state.item.name}
        materials={prMaterialsData.themes}
      />
      <Drawer />
      <Suspense fallback={<Loader />}>
        <Scene>
          <Canvas raycaster={{ firstHitOnly: true }}>
            <directionalLight intensity={2} position={[0.5, -0.5, 0.1]} />
            {!prMaterials || !prMaterialOptions || !selectedMaterials ? null : (
              <ProductScene
                onClick={onPrMeshClick}
                modelUrl={location.state.item.modelUrl}
                materials={selectedMaterials}
                onPointerMissed={onPointerMissed}
                onPointerEnter={onPointerEnter}
                onPointerLeave={onPointerLeave}
              />
            )}

            <Environment
              resolution={1024}
              background={false}
              files={"assets/hdri/Industrial_Room.hdr"}
            ></Environment>

            <OrbitControls target={[0, 0, 0]} />
            <PerspectiveCamera position={[2, 0.2, 0.1]} makeDefault />
          </Canvas>
        </Scene>
      </Suspense>
    </Container>
  );
};

const Container = styled.div`
  z-index: -1;
  position: relative;
  height: 100vh;
  width: 100vw;
  min-width: 750px;
  background: #f9f9f9;
`;
const Scene = styled.div`
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  height: calc(100% - 56px);
  width: calc(100% - 320px);
  background: linear-gradient(
      180deg,
      rgba(249, 249, 249, 0) 39.65%,
      rgba(171, 171, 171, 0.2) 63.28%
    ),
    radial-gradient(56.64% 56.64% at 50% 50%, #e3e3e3 0%, #ffffff 100%);
  overflow-y: hiden;
`;
