import JSZip from "jszip";
import { saveAs } from "file-saver";

export const onDownloadMaterial = (obj) => {
  const zip = new JSZip();
  const files = Object.values(obj);
  for (let file = 0; file < files.length; file++) {
    zip.file(files[file], files[file]);
  }
  zip.generateAsync({ type: "blob" }).then((content) => {
    saveAs(content, "materials.zip");
  });
};
export const onDownloadAllMaterials = (obj) => {
  const zip = new JSZip();
  const items = Object.values(obj);
  const result = items.map(({ textures }) => {
    return textures;
  });
  const files = result.flatMap((el) => {
    const values = Object.values(el);
    const arr = [];
    values.map((element) => {
      arr.push(element);
    });
    return arr;
  });
  for (let file = 0; file < files.length; file++) {
    zip.file(files[file], files[file]);
  }
  zip.generateAsync({ type: "blob" }).then((content) => {
    saveAs(content, "materials.zip");
  });
};
export const onDownloadMaterialOptions = (filesArr) => {
  const zip = new JSZip();
  const result = filesArr.map(({ textures }) => {
    return textures;
  });
  const files = result.flatMap((el) => {
    const values = Object.values(el);
    const arr = [];
    values.map((element) => {
      arr.push(element);
    });
    return arr;
  });
  for (let file = 0; file < files.length; file++) {
    zip.file(files[file], files[file]);
  }
  zip.generateAsync({ type: "blob" }).then((content) => {
    saveAs(content, "materials.zip");
  });
};
export const onDownloadModel = (string) => {
  const zip = new JSZip();

  zip.file(string, "file");

  zip.generateAsync({ type: "blob" }).then((content) => {
    saveAs(content, "3Dmodel.zip");
  });
};
