import Model from "../geometry/Model";
import { Suspense } from "react";
import { getTexture } from "../../lib/helpers/wrapTexture";
import { ContactShadows } from "@react-three/drei";

export const ProductScene = (props) => {
  let materialsArr;
  let texturesObj;
  let texturesArr = [];
  let materialsObj = {};
  if (props.materials) {
    materialsArr = Object.entries(props.materials.materials);
    materialsArr.map((item) => {
      let textureMaps = Object.entries(item[1].textures).filter((key) => {
        if (key.indexOf("map") === 0 || key[0].endsWith("Map")) {
          return key;
        }
      });
      const entries = new Map(textureMaps);
      const obj = Object.fromEntries(entries);

      let textures = getTexture(
        obj,
        item[1].textures.repeat,
        item[1].textures.repeat
      );
      let meshName = item[0];
      let materialName = item[1].name;
      let materialParams = item[1].params;

      texturesObj = {
        [meshName]: {
          materialName: materialName,
          textures: textures,
          params: materialParams,
        },
      };
      texturesArr.push(texturesObj);
    });
    for (const value of Object.values(texturesArr)) {
      Object.assign(materialsObj, value);
    }
  }

  return (
    <Suspense fallback={null}>
      <Model {...props} modelUrl={props.modelUrl} materials={materialsObj} />
      <ContactShadows
        position={[0, -0.44, 0]}
        opacity={0.5}
        width={5}
        height={10}
        blur={1.5}
        far={0.8}
        resolution={256}
        color="#000000"
      />
    </Suspense>
  );
};
