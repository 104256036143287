import { Suspense, useRef, useMemo } from "react";
import { useGLTF, useBVH } from "@react-three/drei";
import { selectionStore } from "../../lib/states/store";

const Model = (props) => {
  const { nodes } = useGLTF(props.modelUrl);
  const model = Object.entries(nodes);
  const modelRef = useRef();

  const selectedMeshName = selectionStore((state) => state.selectedMeshName);

  return (
    <Suspense fallback={null}>
      <group ref={modelRef} rotation={[0, 90, 0]} position={[0, 0, 0]}>
        {model.map((mesh, index) => {
          const meshRef = useRef();
          useBVH(meshRef);
          // console.log(model);
          return useMemo(() => {
            const materialParams = props.materials[mesh[1].name];
            const material = materialParams || mesh[1].material;
            if (materialParams) {
              material.textures.map.needsUpdate = true;

              material.needsUpdate = true;
              material.params.emissive =
                selectedMeshName == mesh[1].name ? "#b70095" : "black";
            }

            return (
              <group key={index}>
                {materialParams ? (
                  <mesh
                    {...props}
                    key={index}
                    receiveShadow
                    castShadow
                    geometry={mesh[1].geometry}
                    material={mesh[1].material}
                    dispose={null}
                    ref={meshRef}
                    name={mesh[1].name}
                  >
                    <meshPhysicalMaterial
                      name={mesh[1].material.name}
                      map={material.textures.map}
                      envMapIntensity={material.textures.envMapIntensity}
                      emissiveMap={material.emissiveMap}
                      emissive={material.params.emissive}
                      emissiveIntensity={material.emissiveIntensity}
                      metalness={material.params.metalness}
                      metalnessMap={material.textures.metalnessMap}
                      roughness={material.params.roughness}
                      roughnessMap={material.textures.roughnessMap}
                      normalMap={material.textures.normalMap}
                      normalScale={material.params.normalScale}
                      bumpMap={material.textures.bumpMap}
                      bumpMapScale={material.params.bumpMapScale}
                      displacementMap={material.textures.displacementMap}
                      displacementMapScale={material.params.displacementScale}
                      aoMap={material.textures.aoMap}
                      aoMapIntensity={material.params.aoMapIntensity}
                      clearcoat={material.params.clearcoat}
                      clearcoatMap={material.textures.clearcoatMap}
                      clearcoatNormalMap={material.textures.clearcoatNormalMap}
                      clearcoatNormalScale={
                        material.params.clearcoatNormalScale
                      }
                      clearcoatRoughnessMap={
                        material.textures.clearcoatRoughnessMap
                      }
                      ior={material.params.ior}
                      reflectivity={material.params.reflectivity}
                      sheenColor={material.params.sheencolor}
                      sheen={material.params.sheen}
                      sheenColorMap={material.textures.sheenColorMap}
                      sheenRoughness={material.params.sheenRoughness}
                      sheenRoughnessMap={material.textures.sheenRoughnessMap}
                      specularIntensity={material.params.specularIntensity}
                      specularIntensityMap={
                        material.textures.specularIntensityMap
                      }
                      opacity={material.params.opacity}
                      transparent={true}
                    />
                  </mesh>
                ) : (
                  <mesh
                    {...props}
                    key={index}
                    receiveShadow
                    geometry={mesh[1].geometry}
                    material={mesh[1].material}
                    dispose={null}
                    onPointerEnter={props.onPointerEnter}
                    onPointerLeave={props.onPointerLeave}
                  />
                )}
              </group>
            );
          }, [
            selectedMeshName == mesh[1].name,
            props.materials[mesh[1].name] &&
              props.materials[mesh[1].name].materialName,
          ]);
        })}
      </group>
    </Suspense>
  );
};
export default Model;
