import create from "zustand";

export const selectionStore = create(() => ({
  selectedTheme: 0,
  selectedMeshName: "",
  selectedMaterials: null,
  openModal: false,
}));

export const materialStore = create(() => ({
  prMaterials: null,
  prMaterialOptions: null,
  bgMaterials: null,
}));
